/*globals $*/
'use strict';

import lozad from 'lozad'
const getMaps = () => import("./external/functions/maps");
const getSvg = () => import("./external/functions/svg");
const getOwlCarousel = () => import("./external/functions/owl_carousel");
const getHeaderFunctions = () => import("./external/functions/header");

/* DETECT MOBILE */
var isMobile = false;
if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
    isMobile = true;
}

var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = 100; //$('header').outerHeight();

var playing1 = false;

$( document ).ready(function() {

    //LOAD NAVBAR IF NEEDED
    if($("#nav-open-close").length > 0) {
        getHeaderFunctions().then(headerModule => {
            headerModule.showNavBar();
        });
    }

    // LOAD MAPS IF NEEDED
    if( $('.map__container').length > 0 ) {
        getMaps().then(mapsModule => {
            mapsModule.initMap('');
        });
    }
    if( $('.map__container_zone').length > 0 ) {
        getMaps().then(mapsModule => {
            mapsModule.initMapZone('');
        });
    }

     //LOAD OWL CAROUSEL IF NEEDED
    if( $('.owl-carousel-section-promo-full').length > 0 ) {
        getOwlCarousel().then(owlModule => {
            owlModule.initOwlCarouselSectionPromoFull();
        });
    }
    if( $('.owl-carousel-flatslide').length > 0 ) {
        getOwlCarousel().then(owlModule => {
            owlModule.initOwlCarouselFlatSlide();
        });
    }
    if( $('.owl-carousel-zone').length > 0 ) {
        getOwlCarousel().then(owlModule => {
            owlModule.initOwlCarouselZone();
        });
    }
    if( $('.owl-carousel-zone2').length > 0 ) {
        getOwlCarousel().then(owlModule => {
            owlModule.initOwlCarouselZone2();
        });
    }
    if( $('.owl-carousel-zone3').length > 0 ) {
        getOwlCarousel().then(owlModule => {
            owlModule.initOwlCarouselZone3();
        });
    }
    if( $('.owl-carousel-zone-doble').length > 0 ) {
        getOwlCarousel().then(owlModule => {
            owlModule.initOwlCarouselZoneDoble();
        });
    }
    if( $('.owl-carousel-barrio').length > 0 ) {
        getOwlCarousel().then(owlModule => {
            owlModule.initOwlCarouselBarrio();
        });
    }
    if( $('.owl-carousel-4columns').length > 0 ) {
        getOwlCarousel().then(owlModule => {
            owlModule.initOwlCarousel4columns();
        });
    }

    //LOAD SVG IF NEEDED
    if($(".svg").length > 0) {
        getSvg().then(svgModule => {
            svgModule.prepareSvg();
        });
    }

    //intervalo de tiempo para calcular constantemente posicion del scroll
    setInterval(function() {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    $('.we_button').click(function() {
        $('html, body').animate({
            scrollTop: $("#we").offset().top - 30
        }, 1500);
        closeMenu();
    });
    $('.services_button').click(function() {
        $('html, body').animate({
            scrollTop: $("#services").offset().top - 30
        }, 1500);
        closeMenu();
    });
    $('.projects_button').click(function() {
        $('html, body').animate({
            scrollTop: $("#projects").offset().top - 30
        }, 1500);
        closeMenu();
    });
    $('.contact_button').click(function() {
        if($(this).data('id')){
            $('#hundreds__contact_subject').val($(this).data('id'));
        }
        if($(this).data('project')){
            var project = $(this).data('project')
            $('#hundreds__contact_project option[value="'+project+'"]');
            $('#hundreds__contact_project').val($(this).data('project'));
        }
        $('#contact-desplegable').addClass('open');
        //close menu
        if($('#menu-desplegable').hasClass('open') ){
            closeMenu();
            $('#hundreds__contact_subject').val('');
            $('#hundreds__contact_project option[value="none"]');
            $('#hundreds__contact_project').val('none');
        }
    });
    $('.close_contact_button').click(function() {
        $('#contact-desplegable').removeClass('open');
        $('#hundreds__contact_subject').val('');
        $('#hundreds__contact_project option[value="none"]');
        $('#hundreds__contact_project').val('none');

    });


    //parallax en las imagenes de content
    if( $(window).width() > 600 ) {
        $('.scroll-parallax').simpleParallax();
    }

    $('.image-box').each(function(){ calcularPosicion( $(this) ); });
    //usamos esta funcion para activar tambien el play de los videos
    $('.section-video video').each(function(){ playVideo( $(this) ); });

    //control video portada HOME

    $('#videoplay').on('click', function() {
      $('#bgvid').get(0).play();
      $('#videoplay').hide();
      //$('#videobt').show();
      $('.slider-content').addClass('hide');
      if( $('.download-dossier').length ) $('.download-dossier').addClass('hide');
      playing1 = true;
    });
    $('#videobt').on('click', function() {
      if(playing1 == false){
        $('.slider-content').addClass('hide');
        if( $('.download-dossier').length ) $('.download-dossier').addClass('hide');
        $('#bgvid').get(0).play();
        $('#videoplay').hide();
        playing1 = true;
      } else if (playing1 == true){
        $('.slider-content').removeClass('hide');
        if( $('.download-dossier').length ) $('.download-dossier').removeClass('hide');
        $('#videoplay').show();
        $('#bgvid').get(0).pause();
        playing1 = false;
      }
    });

   iframeResize();

    $('.appear-up').each(function(){ calcularPosicion( $(this) ); });
    $('.appear-from-left').each(function(){ calcularPosicion( $(this) ); });
    $('.appear-from-right').each(function(){ calcularPosicion( $(this) ); });


    //DESPLEGABLES FAQ

    $('[id^="faq-button"]').on('click', function() {
        var id = $(this).data('id');
        if($(this).hasClass('selected')){
            closeAllFAQ();
        } else {
            closeAllFAQ();
            $(this).addClass('selected');
            $('#faq-content' + id).slideDown();
        }
    });
    $('[id^="faq2-button"]').on('click', function() {
        var id = $(this).data('id');
        if($(this).hasClass('selected')){
            closeAllFAQ();
        } else {
            closeAllFAQ();
            $(this).addClass('selected');
            $('#faq2-content' + id).slideDown();
        }
    });

    // BANNERS APPEAR /DISSAPEAR HOME
    //banners
    $('#button-disappear').on('click', function(e) {
        if( $('#banners').length ){
            if ( $('#banners').hasClass('disappear') ) {
                $('#banners').removeClass('disappear');
            } else {
                 $('#banners').addClass('disappear');
            }
        }
    });
    //popups
    $('#close-popup-layer_marobert-badalona').click(function(){ 
        $('#popup_marobert-badalona').removeClass('show'); 
    });
    $('#close-popup-button_marobert-badalona').click(function(){ 
        $('#popup_marobert-badalona').removeClass('show'); 
    }); 
    $('#close-popup-layer_luzzes-vallecas').click(function(){ 
        $('#popup_luzzes-vallecas').removeClass('show'); 
    });
    $('#close-popup-button_luzzes-vallecas').click(function(){ 
        $('#popup_luzzes-vallecas').removeClass('show'); 
    });  
    $('#close-popup-layer_nuve-vallecas').click(function(){ 
        $('#popup_nuve-vallecas').removeClass('show'); 
    });
    $('#close-popup-button_nuve-vallecas').click(function(){ 
        $('#popup_nuve-vallecas').removeClass('show'); 
    });  
    $('#close-popup-layer_christmas').click(function(){ 
        $('#popup_christmas').removeClass('show'); 
    });
    $('#close-popup-button_christmas').click(function(){ 
        $('#popup_christmas').removeClass('show'); 
    });  

});

$(window).scroll(function(){
    didScroll = true;
    $('.image-box').each(function(){ calcularPosicion( $(this) ); });
    //usamos esta funcion para activar tambien el play de los videos
    $('.section-video video').each(function(){ playVideo( $(this) ); });

    $('.appear-up').each(function(){ calcularPosicion( $(this) ); });
    $('.appear-from-left').each(function(){ calcularPosicion( $(this) ); });
    $('.appear-from-right').each(function(){ calcularPosicion( $(this) ); });

    var alto = $(window).height();
    var wScroll = $(window).scrollTop();

    //si se deja el video playing y baja scroll, que se pare solo
    /*if( $('#slider').length && ( $('#slider').offset().top + alto ) < wScroll ){
        if (playing1 == true){
            $('.slider-content').removeClass('hide');
            $('#videoplay').show();
            $('#bgvid').get(0).pause();
            playing1 = false;
        }
    }*/
    if( $('#slider').length ) { // si estamos en la home.. autoseleccionamos los menus según scroll
        var menu1pos = $('#we').offset().top - 50;
        var menu1height = $('#we').height();
        var menu2pos = $('#services').offset().top - 50;
        var menu2height = $('#services').height();
        var menu3pos = $('#projects').offset().top - 50;
        var menu3height = $('#projects').height();
        if ( wScroll > menu1pos && wScroll < (menu1pos + menu1height) ){
            $('.header__nav ul li.we_button').addClass('selected');
        } else {
            $('.header__nav ul li.we_button').removeClass('selected');
        }
        if ( wScroll > menu2pos && wScroll < (menu2pos + menu2height) ){
            $('.header__nav ul li.services_button').addClass('selected');
        } else {
            $('.header__nav ul li.services_button').removeClass('selected');
        }
        if ( wScroll > menu3pos /*&& wScroll < (menu3pos + menu3height)*/ ){
            $('.header__nav ul li.projects_button').addClass('selected');
        } else {
            $('.header__nav ul li.projects_button').removeClass('selected');
        }
    }
});
$(window).resize(function(){
    iframeResize();
});


function hasScrolled() {
    var st = $(window).scrollTop();

    if (st > navbarHeight){
        $('.header').addClass('fixed');
    } else {
        $('.header').removeClass('fixed');
    }
}

function calcularPosicion(element) {
    var alto = $(window).height() - 10;
    var wScroll = $(window).scrollTop();

    if(element.length && (element.offset().top - alto) < wScroll ){
        element.addClass('show');
    }
}
function playVideo(element) {
    var alto = $(window).height() - 10;
    var wScroll = $(window).scrollTop();

    if(element.length && (element.offset().top - alto) < wScroll ){
        if( !element.hasClass('played') ){
            element.get(0).play();
            element.addClass('played');
        }
    }
}

function closeMenu() {
    $('#header').removeClass('menu-opened');
    $('#nav-open-close').removeClass('open');
    $('#menu-desplegable').removeClass('open');
}

//Lazy load of all the images
document.addEventListener('DOMContentLoaded', function(){
    if($(".lozad").length > 0) {
        const observer = lozad('.lozad', {
          threshold: 0.1,
          load: function(el) {
            if(el.dataset.src){ el.src = el.dataset.src; }
            if(el.dataset.backgroundImage){ el.style.backgroundImage = "url('"+el.dataset.backgroundImage+"')"; }
          },
        });
        observer.observe();
    }
});

function iframeResize() {
    $('iframe').each(function () {
        var ratio = $(this).attr('height') / $(this).attr('width');
        var contentWidth = $(this).parent().width();
        $(this).attr('width', contentWidth).attr('height', contentWidth * ratio);
    });
}

function closeAllFAQ() {
    $('[id^="faq-content"]').slideUp();
    $('[id^="faq-button"]').removeClass('selected');
    $('[id^="faq2-content"]').slideUp();
    $('[id^="faq2-button"]').removeClass('selected');
}




