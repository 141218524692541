/*globals $*/
'use strict';

const getValidationFunctions = () => import("./validate");

$( document ).ready(function() {

    $('#hundreds__contact_submit-button').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateContactForm();
        });
    });

    $('#hundreds__opendoors_submit-button').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateOpenDoorsForm();
        });
    });

});

